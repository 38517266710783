@import url("https://rsms.me/inter/inter.css");

html {
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --background-color: #161616;
  --panel-background-color: #292827;
}

html,
body {
  height: 100%;
}

body {
  background: var(--background-color);
}

.panel {
  position: fixed;
  top: 24px;
  left: 24px;
  background: var(--panel-background-color);
  border: 1px solid #111111;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  color: white;
}

.panel__upload-button {
  color: white;
  margin-bottom: 16px;
}

.panel__label {
  font-weight: 500;
  margin-bottom: 8px;
}
